import * as React from 'react';
import styled from 'styled-components';

const StyledSectionTitle = styled.div.attrs(({ level }) => {
  let classes = [];
  let tag = '';
  switch(level) {
    case 1: {
      tag = 'h1';
      classes = [...classes, 'text-5xl mb-6 text-gray-900 font-bold tracking-wide'];
      break;
    }
    case 2: {
      tag = 'h2';
      classes = [...classes, 'text-2xl text-gray-600 tracking-widest font-light'];
      break;
    }
    default: {
      classes = [...classes];
      break;
    }
  }
  return {
    className: classes.join(' '),
    as: tag,
  }
})``;

const SectionTitle = ({ level, children }) => (
  <StyledSectionTitle level={level}>
    { children }
  </StyledSectionTitle>
);

export default SectionTitle;