import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMenu = styled.nav.attrs(({ orientation }) => {
  if (orientation === 'vertical') {
    return {
      className: 'flex flex-col items-start',
    };
  }

  return {
    className: 'w-full hidden md:block',
  };
})``;

const Menu = (props) => {
  return (
    <StyledMenu orientation={props.orientation}>
      {props.children()}
    </StyledMenu>
  )
};

Menu.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  })),
};

Menu.defaultProps = {
  orientation: 'horizontal',
};

export default Menu;

