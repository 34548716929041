import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FaEnvelope } from 'react-icons/fa';

const StyledEMailLink = styled.a.attrs(() => ({
  className: 'text-blue-500 hover:text-red-500',
}))``;

const StyledEnvelope = styled(FaEnvelope).attrs(() => ({
  className: 'inline-block text-xs mr-1',
}))`
  transform: translateY(-1.5px)
`;

const EMailLink = ({ email, text }) => (
  <StyledEMailLink href={`mailto:${email}`}><StyledEnvelope /> {text || email}</StyledEMailLink>
);

EMailLink.propTypes = {
  email: PropTypes.string.isRequired,
  text: PropTypes.string,
};
export default EMailLink;