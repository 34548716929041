import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledFooterNewsLink = styled(Link).attrs(() => ({
  className: 'block pl-2 border-l-2 border-gray-800 py-1 text-sm text-gray-500 hover:text-red-500',
  activeClassName: 'border-l-2 border-red-500'
}))`
  &:hover > * {
    color: inherit
  }
`;

const StyledFooterNewsDate = styled.div.attrs(() => ({
  className: 'text-xs text-gray-700',
}))``;


const FooterNewsLink = ({ title, date, ...props }) => (
  <StyledFooterNewsLink {...props}>
    {title}
    <StyledFooterNewsDate>{date}</StyledFooterNewsDate>
  </StyledFooterNewsLink>
);
export default FooterNewsLink;
