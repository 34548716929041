import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SectionTitle from '../../atoms/SectionTitle/SectionTitle';
import Container from '../../atoms/Container/Container';


const renderSectionIntro = (title, lead) => {
  if (!!title || !!lead) {
    return (<StyledSectionIntro>
      {!!title ? <SectionTitle level={1}>{title}</SectionTitle> : undefined}
      {!!lead ? <SectionTitle level={2}>{lead}</SectionTitle> : undefined}
    </StyledSectionIntro>);
  }
};

const StyledSection = styled.div.attrs(({ type }) => ({
  className: `py-32 ${type === 'Primary' ? 'bg-gray-100' : 'bg-gray-200'} border-b border-gray-300`,
}))``;

const StyledSectionIntro = styled.div.attrs(() => ({
  className: 'mb-24',
}))``;

const StyledContent = styled.div.attrs(() => ({
  className: '',
}))``;

const Section = ({ title, lead, type, children }) => {
  return (
    <StyledSection type={type}>
      <Container>
        {renderSectionIntro(title, lead)}
        {children ? <StyledContent>{children}</StyledContent> : undefined}
      </Container>
    </StyledSection>
  );
};


Section.propTypes = {
  type: PropTypes.oneOf(['Primary', 'Secondary']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  lead: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

Section.defaultProps = {
  type: 'Primary',
};

export default Section;