import * as React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div.attrs(() => ({
  className: 'container mx-auto px-6',
}))``;

const Container = ({ children }) => (
  <StyledContainer>
    { children }
  </StyledContainer>
);

export default Container;