/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/main.css';

import Header from '../organisms/Header/Header';
import Footer from '../organisms/Footer/Footer';

const DefaultLayout = ({ children }) => {
  return (
    <div className="antialiased text-bg-900 bg-gray-100">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
