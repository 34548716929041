import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FaTwitter, FaLinkedin } from 'react-icons/fa';

const iconClasses = 'text-blue-500 inline-block text-xs mr-1 hover:text-red-500';

const StyledSocialLink = styled.a.attrs(() => ({
  className: 'inline-block mr-2',
}))``;

const StyledTwitter = styled(FaTwitter).attrs(() => ({
  className: iconClasses,
}))`
  transform: translateY(-1.5px)
`;

const StyledLinkedIn = styled(FaLinkedin).attrs(() => ({
  className: iconClasses,
}))`
  transform: translateY(-1.5px)
`;

const SocialLink = ({ link, type }) => (
  <StyledSocialLink href={link} target="_blank" rel="noopener noreferrer">{
    renderIcon(type)
  }</StyledSocialLink>
);

SocialLink.propTypes = {
  type: PropTypes.oneOf(['twitter', 'linkedIn']).isRequired,
  link: PropTypes.string,
};

const renderIcon = (type) => {
  switch (type) {
    case 'twitter':
      return (<StyledTwitter />);
    case 'linkedIn':
      return (<StyledLinkedIn />);
    default:
      return undefined;
  }
};
export default SocialLink;