import styled from 'styled-components';
import { Link } from 'gatsby';

const HeaderMenuLink = styled(Link).attrs(({ to }) => ({
  className: 'inline-block mx-1 px-2 py-2 text-center text-xs font-bold tracking-widest uppercase hover:text-red-500 focus:outline-none focus:shadow-outline',
  activeClassName: 'border-b-2 border-red-500',
  partiallyActive: to !== '/',
}))``;

export default HeaderMenuLink;
