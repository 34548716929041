import * as React from 'react';
import styled from 'styled-components';


import Menu from '../../molecules/Menu/Menu';
import LogoLink from '../../atoms/LogoLink/LogoLink';
import { graphql, useStaticQuery } from 'gatsby';
import HeaderMenuLink from '../../atoms/HeaderMenuLink/HeaderMenuLink';
import MobileMenuLink from '../../atoms/MobileMenuLink/MobileMenuLink';
import MobileMenu from '../../molecules/MobileMenu/MobileMenu';

const StyledHeader = styled.div.attrs(() => ({
  className: 'bg-white text-gray-900 sticky inset-0 z-20 flex px-6 p justify-between',
}))``;

const HeaderItem = styled.div.attrs(() => ({
  className: 'self-center',
}))``;

const Header = () => {
  const data = useStaticQuery(graphql`
    query headerMenu {
      menu: contentfulMenu(menuLocation: {eq: "Header"}) {
        items: menuItems {
          id
          title
          content {
            slug
          }
        }
      }
    }
  `);

  return (
    <StyledHeader>
      <HeaderItem>
        <LogoLink />
      </HeaderItem>
      <HeaderItem>
        <Menu>
          {() => (
            data.menu.items.map(item => (
              <HeaderMenuLink key={item.id} to={item.content.slug}>{item.title}</HeaderMenuLink>
            ))
          )}
        </Menu>
        <MobileMenu>
          {() => (
            data.menu.items.map(item => (
              <MobileMenuLink key={item.id}  to={item.content.slug}>{item.title}</MobileMenuLink>
            ))
          )}
        </MobileMenu>
      </HeaderItem>
    </StyledHeader>
  );
};

export default Header;