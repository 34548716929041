import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledInternalLink = styled(Link).attrs(() => ({
  className: 'text-red-500 hover:text-blue-500',
}))``;

const InternalLink = ({ to, children }) => (
  <StyledInternalLink to={to}>
    {children}
  </StyledInternalLink>
);

export default InternalLink;