import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const LogoLink = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logos/dark-logo.png" }) {
        childImageSharp {
          fixed(height: 68) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <Link to="/">
      <Img fixed={data.logo.childImageSharp.fixed} />
    </Link>
  );
};

export default LogoLink;