import * as React from 'react';
import styled from 'styled-components';
import Divider from '../../atoms/Divider/Divider';
import { graphql, useStaticQuery } from 'gatsby';
import Menu from '../../molecules/Menu/Menu';
import FooterMenuLink from '../../atoms/FooterMenuLink/FooterMenuLink';
import FooterNewsLink from '../../atoms/FooterNewsLink/FooterNewsLink';
import EMailLink from '../../atoms/EMailLink/EMailLink';
import SocialLink from '../../atoms/SocialLink/SocialLink';

const StyledFooter = styled.footer.attrs(() => ({
  className: 'bg-gray-900 pt-16',
}))``;


const StyledFooterContentContainer = styled.div.attrs(() => ({
  className: 'container mx-auto px-6 text-white flex justify-around flex-col items-center md:flex-row md:items-start',
}))``;

const StyledFooterContentColumn = styled.div.attrs(({ mobile }) => {
  if (mobile) {
    return {
      className: 'text-mt-6 md:mt-0',
    };
  }
  return {
    className: 'hidden md:block mt-6 md:mt-0',
  };
})``;

const StyledContent = styled.div.attrs(({ indent }) => ({
  className: `${indent ? 'pl-2 border-l-2 border-gray-800' : ''} py-1 text-sm text-gray-500`,
}))``;

const StyledCopyrightContainer = styled.div.attrs(() => ({
  className: 'container mx-auto px-6 text-gray-700 text-xs font-thin pb-8',
}))``;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query footer {
      site {
        siteMetadata {
          ...companyDetails
        }
      }

      menu: contentfulMenu(menuLocation: {eq: "Footer"}) {
        ...menuContent
      }

      news: allContentfulNewsArticle(sort: {fields: publicationStart, order: DESC}, limit: 5) {
        nodes {
          ...newsShortContent
        }
      }
    }
  `);

  const StyledFooterTitle = styled.h4.attrs(() => ({
    className: 'mb-4 text-white uppercase font-semibold text-sm',
  }))``;

  const companyDetails = data.site.siteMetadata.companyDetails;
  const menu = data.menu;
  const newsArticles = data.news.nodes;

  return (
    <StyledFooter>
      <StyledFooterContentContainer>
        <StyledFooterContentColumn mobile={false}>
          <StyledFooterTitle>NanoLockin News</StyledFooterTitle>
          {
            newsArticles.map(newsArticle => (
              <FooterNewsLink
                key={newsArticle.id}
                to={`/news`}
                title={newsArticle.title}
                date={newsArticle.publicationStart}
              />
            ))
          }
        </StyledFooterContentColumn>
        <StyledFooterContentColumn mobile={false}>
          <StyledFooterTitle>More Information</StyledFooterTitle>
          <Menu
            orientation="vertical"
          >
            {() => (
              menu.items.map(item => (
                <FooterMenuLink key={item.id} to={item.content.slug}>{item.title}</FooterMenuLink>
              ))
            )}
          </Menu>
        </StyledFooterContentColumn>
        <StyledFooterContentColumn mobile={true}>
          <StyledFooterTitle>About NanoLockin</StyledFooterTitle>
          <StyledContent indent>
            {companyDetails.name} {companyDetails.form}<br />
            {companyDetails.addressLine01}<br />
            {companyDetails.addressLine02}<br />
            {companyDetails.zipCode} {companyDetails.city}<br />
            {companyDetails.country}<br />
          </StyledContent>
          <StyledContent indent>
            <SocialLink type="linkedIn" link={companyDetails.social.linkedIn} />
            <SocialLink type="twitter" link={companyDetails.social.twitter} />
          </StyledContent>
        </StyledFooterContentColumn>
      </StyledFooterContentContainer>
      <Divider />
      <StyledCopyrightContainer>
        <div>
          © {new Date().getFullYear()}, {companyDetails.name} {companyDetails.form}. All Rights Reserved. Terms &
          Conditions | Cookie policy
        </div>
      </StyledCopyrightContainer>
    </StyledFooter>
  );
};

export default Footer;