import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { FaBars, FaTimes } from 'react-icons/fa';

const MobileMenu = (props) => {
  const [open, setOpen] = useState(false);
  const navAnimation = useSpring({
    transform: open ? `translate3d(0,0,0)` : `translate3d(100%,0,0)`,
  });
  return (
    <div className="block md:hidden" style={navAnimation}>
      <button className="text-3xl hover:text-red-500 focus:outline-none focus:shadow-outline" onClick={() => setOpen(!open)}><FaBars /></button>
      <animated.nav className="fixed top-0 left-0 w-full h-full text-xl bg-white z-10 flex flex-col"
                    style={navAnimation}>
        {props.children()}
        <button
          className="font-light tracking-widest mx-6 py-2 my-6 text-center uppercase text-blue-500 hover:text-red-500 focus:outline-none focus:shadow-outline"
          onClick={() => setOpen(false)}>
          <FaTimes className="inline-block mr-2 -mt-1" />CLOSE
        </button>
      </animated.nav>
    </div>
  );
};

export default MobileMenu;