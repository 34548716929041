import * as React from 'react';
import { Link } from 'gatsby';

const MobileMenuLink = ({ children, ...props }) => (
  <Link
    className="block mx-6 py-2 my-6 text-center font-bold tracking-widest uppercase hover:text-red-500 focus:outline-none focus:shadow-outline"
    activeClassName="border-l-4 border-red-500"
    {...props}
  >
    {children}
  </Link>
);
export default MobileMenuLink;
